// extracted by mini-css-extract-plugin
export var tileContent = "w_tg d_w d_H d_Z";
export var teamTextLeft = "w_th d_dv";
export var teamTextCenter = "w_tj d_dw";
export var teamTextRight = "w_tk d_dx";
export var alignLeft = "w_qh d_w d_bz d_fp d_bG d_dv";
export var alignCenter = "w_bP d_w d_bz d_fq d_bD d_dw";
export var alignRight = "w_qj d_w d_bz d_fr d_bH d_dx";
export var teamContainer = "w_tl d_dW";
export var teamContainerFull = "w_tm d_dT";
export var teamRowWrapper = "w_tn d_cc";
export var teamTileWrapper = "w_j3 d_j3 d_Z d_cv";
export var teamTileSquareWrapper = "w_tp d_j4 d_Z d_cv";
export var teamTileRoundWrapper = "w_j4 d_j4 d_Z d_cv";
export var teamTileNoGuttersWrapper = "w_j5 d_j5 d_Z";
export var teamHoverNoGutters = "w_j6 d_j6 d_0 d_w d_H d_bz d_bD d_bP d_bm";
export var teamImageText = "w_kc d_kc d_bl d_w d_by";
export var teamInfoWrapperSquare = "w_tq d_j7";
export var teamInfoWrapperRound = "w_j7 d_j7";
export var teamInfoWrapper = "w_tr d_j8 d_0";
export var teamInfoWrapperNoGutters = "w_j9 d_j9 d_w d_H d_bD d_bM d_bJ d_c7";
export var teamImgWrapper = "w_jZ d_jZ";
export var teamImgWrapperAlt = "w_j0 d_j0";
export var teamImgWrapperNoGutters = "w_kb d_kb";
export var teamHeader = "w_ts d_cw";
export var teamHeaderAlt = "w_tt d_cw";
export var teamHeaderNoGutters = "w_tv d_cw d_cD";