// extracted by mini-css-extract-plugin
export var customText = "p_qw d_dv d_cs d_cg";
export var videoIframeStyle = "p_pS d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "p_qx d_cs d_cg d_Z";
export var customRow = "p_qb d_bD d_bf";
export var quoteWrapper = "p_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "p_pX d_H";
export var masonryImageWrapper = "p_p3";
export var title = "p_qy";
export var Title3Small = "p_qz s_qz s_qM s_qV";
export var Title3Normal = "p_qB s_qB s_qM s_qW";
export var Title3Large = "p_qC s_qC s_qM s_qX";