// extracted by mini-css-extract-plugin
export var carouselContainer = "C_qm d_w d_H d_bf d_Z";
export var carouselContainerCards = "C_t4 C_qm d_w d_H d_bf d_Z";
export var carouselContainerSides = "C_t5 d_w d_H d_Z";
export var prevCarouselItem = "C_t6 d_w d_H d_0 d_k";
export var prevCarouselItemL = "C_t7 C_t6 d_w d_H d_0 d_k";
export var moveInFromLeft = "C_t8";
export var prevCarouselItemR = "C_t9 C_t6 d_w d_H d_0 d_k";
export var moveInFromRight = "C_vb";
export var selectedCarouselItem = "C_vc d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "C_vd C_vc d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "C_vf C_vc d_w d_H d_Z d_bf";
export var nextCarouselItem = "C_vg d_w d_H d_0 d_k";
export var nextCarouselItemL = "C_vh C_vg d_w d_H d_0 d_k";
export var nextCarouselItemR = "C_vj C_vg d_w d_H d_0 d_k";
export var arrowContainer = "C_vk d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "C_vl C_vk d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "C_vm C_vl C_vk d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "C_vn C_vk d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "C_vp";
export var nextArrowContainerHidden = "C_vq C_vn C_vk d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "C_kG d_0";
export var prevArrow = "C_vr C_kG d_0";
export var nextArrow = "C_vs C_kG d_0";
export var carouselIndicatorContainer = "C_vt d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "C_vv d_w d_bz d_bF";
export var carouselText = "C_vw d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "C_vx C_vw d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "C_vy d_b7";
export var carouselIndicator = "C_vz C_vy d_b7";
export var carouselIndicatorSelected = "C_vB C_vy d_b7";
export var arrowsContainerTopRight = "C_vC d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "C_vD d_0 d_bl d_bC";
export var arrowsContainerSides = "C_vF d_0 d_bl d_bC";
export var smallArrowsBase = "C_vG d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "C_vH C_vG d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "C_vJ C_vH C_vG d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "C_vK C_vG d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "C_vL C_vK C_vG d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "C_p9";
export var iconSmall = "C_vM";
export var multipleWrapper = "C_vN d_bC d_bF d_bf";
export var multipleImage = "C_vP d_bC";
export var sidesPrevContainer = "C_vQ C_vH C_vG d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "C_vR C_vH C_vG d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";